import Image from 'next/image';
import React from 'react';

// Render a reusable circle with a WhatsApp icon linked to a specific message.
const WppCircle = ({ className }) => {
  const phoneNumber = '2235644092';
  const message = 'Hola, quiero saber más información de Marine broker';
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className={` ${className} w-[60px] h-[60px] rounded-full flex justify-center items-center bg-[#25D366] duration-300 hover:scale-110`}
    >
      <Image
        src={'/brands/whatsapp.png'}
        width={35}
        height={100}
        priority={true}
        quality={100}
        alt="wppLogo"
      />
    </a>
  );
};

export default WppCircle;
