"use client";
import { createCookie, getCookie } from '@/actions/cookiesActions';
import emailjs from '@emailjs/browser';

export async function contact(prev, formData) {

  const body = {
    user_name: formData.get('user_name'),
    user_email: formData.get('user_email'),
    message: formData.get('message')
  }

  const { cookie } = await getCookie('emails');
  if (cookie)
    if (cookie >= 3)
      return { message: 'No puedes enviar mas mails en el dia', status: 401 }

  try {
    const response = await emailjs.send("service_e98xkuq", "template_m6hqsqx", body, 'gQ8sJoVzvDr6-TAtC');

    switch (response.status) {
      case 200:
        const moreSpam = Number(cookie) + 1
        const spamValue = moreSpam.toString()
        await createCookie('emails', spamValue)
        return { message: 'Mensaje enviado con exito', status: 200 }
      default:
        return { message: 'Error al enviar un mensjae', status: 400 }
    }
  } catch (error) {
    console.log(error)
    return { message: 'Error al enviar un mensjae', status: 400 }
  }
}
