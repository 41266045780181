"use client"
import { useEffect, useState } from "react"
import Image from "next/image"
import { motion } from "framer-motion"

/**
 * This component is responsible for rendering the home hero section.
 */
const Home = () => {
  
  const [scrollY, setScrollY] = useState(0)

  // Handle parallax effect on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  // Smooth scroll to the next section
  const handleScroll = (e, target, offset = 0) => {
    e.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <section
      className="w-full h-[100vh] flex flex-col justify-start items-center relative overflow-hidden bg-[#0B0814]"
      id="home"
    >
      <div
        className="absolute inset-0 w-full h-full"
      >
        <Image
          src="/backGrounds/puerto.jpg"
          fill
          className="object-cover scale-110"
          alt="Puerto marítimo"
          quality={100}
          priority
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-black/20 to-black/50 z-0"></div>
      <header className="z-10 h-full w-full max-w-7xl mx-auto px-4 md:px-6 flex flex-col justify-center items-center">
        <motion.div
          className="text-center space-y-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="font-bold text-white text-4xl md:text-6xl 2xl:text-7xl tracking-wider">
            MARINE <span className="text-primary">BROKER</span>
          </h1>
          <div className="relative">
            <h2 className="font-medium text-xl md:text-2xl 2xl:text-3xl text-secondary">
              Especialistas en seguros marítimos
            </h2>
            <div className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-secondary rounded-full"></div>
          </div>
          <p className="max-w-2xl mx-auto text-white/90 mt-8 text-base md:text-lg">
            Protegemos tus activos marítimos con soluciones de seguros personalizadas y respaldadas por los mejores
            profesionales del sector.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center mt-8">
            <button
              onClick={(e) => handleScroll(e, "#contact", 250)}
              className="px-6 py-3 bg-primary text-white font-medium w-auto rounded-md hover:bg-primary/90 transition-colors shadow-lg hover:shadow-primary/20 hover:shadow-xl"
            >
              Contactanos
            </button>
            <button
              onClick={(e) => handleScroll(e, "#about", 100)}
              className="px-6 py-3 bg-white/10 text-white border border-white/30 backdrop-blur-sm font-medium w-auto rounded-md hover:bg-white/20 transition-colors"
            >
              Conoce más
            </button>
          </div>
        </motion.div>
      </header>
    </section>
  )
}

export default Home

