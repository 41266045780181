import Image from "next/image"
import Link from "next/link"
import { ExternalLink } from "lucide-react"

const AttendanceCard = ({ companyAlt, link, companyImg, data }) => {
  return (
    <div className="w-full max-w-xs flex flex-col bg-white rounded-lg shadow-md border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-lg hover:border-primary/20">
      {/* Card Header with Company Logo */}
      <div className="p-6 flex justify-center items-center border-b border-gray-100 bg-white">
        <div className="relative h-16 w-full flex items-center justify-center">
          <Image
            src={companyImg || "/placeholder.svg"}
            alt={companyAlt}
            width={150}
            height={60}
            className="object-contain max-h-16"
          />
        </div>
      </div>

      {/* Card Content with Contact Information */}
      <div className="flex-grow p-6 flex flex-col gap-y-4">
        {data.map(({ title, subtitle }) => (
          <div className="flex flex-col items-center text-center" key={subtitle}>
            <h3 className="text-xs text-gray-500 mb-1">{title}</h3>
            <p className="text-lg font-semibold text-gray-900">{subtitle}</p>
          </div>
        ))}
      </div>

      {/* Card Footer with Contact Button */}
      <div className="p-6 pt-2 flex justify-center">
        <Link
          href={link}
          target="_blank"
          className="w-full flex items-center justify-center gap-2 py-3 px-4 bg-primary text-white font-medium rounded-md transition-all duration-300 hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2"
        >
          Contactar compañía
          <ExternalLink size={16} />
        </Link>
      </div>
    </div>
  )
}

export default AttendanceCard

