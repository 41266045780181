'use client'
import React from 'react';

const CommonTextArea = ({ name, onChange, value, title = '', minlength = 0, maxlength = 1000, showMaxlengthCounter = false, required = false, type = 'text', testId = '', placeHolder = 'Escribe aqui...', width = 'w-80', height = 'h-28 md:h-32 xl:h-44', titleColor = 'text-black' }) => {

  return (
    <label className={` ${width} flex flex-col justify-center relative`}>
      <div className={`text-xs 2xl:text-sm font-semibold flex justify-between w-full ${titleColor}`}>{title} {showMaxlengthCounter && <span>{`${inputValue.length}/${maxlength}`}</span>}</div>
      <textarea
        onChange={onChange}
        type={type}
        name={name}
        required={required}
        minLength={minlength}
        maxLength={maxlength}
        placeholder={placeHolder}
        value={value}
        data-test-id={testId}
        className={`w-full ${height} p-2 text-xs font-semibold rounded text-black ring-1 ring-gray-300 focus:ring-2 focus:ring-primary focus:outline-none`}
      ></textarea>
    </label>
  );
}

export default CommonTextArea;
