'use client'
import React, { useActionState, useEffect } from 'react'
import { CustomButton, CommonInput } from '@/components'
import CommonTextArea from '@/components/common/CommonTextArea';
import { contactInputs } from '@/utils'
import { toast } from 'sonner';
import { contact } from '@/app/client/formClientActions';

/**
 * @function Contact - This component is responsible for displaying a contact section with a form and email sending functionality using the EmailJS library.
 * The function sends the data entered by the user to Marine Broker's email.
 * It checks if the user has sent more than 3 emails per day using cookies, preventing spam.
 * The function provides feedback via toast notifications. 
 */
const ContactForm = () => {

  const [data, formAction, pending] = useActionState(contact, { status: false, message: '' })

  useEffect(() => {
    if (data.status) {
      let { status, message } = data;
      switch (status) {
        case 200:
          toast.success(message)
          break;
        case 401:
          toast.warning(message)
          break;
        default: toast.error(message)
          break;
      }
    }
  }, [data])

  return (
    <form action={formAction} className="space-y-6 flex flex-col justify-around">
      <div className="flex flex-col md:flex-row gap-4 md:gap-6">
        {
          contactInputs.map(({ name, title, placeHolder, type }) => <label key={name} className='w-full md:w-1/2 flex flex-col justify-center relative'>
            <div className='text-xs 2xl:text-sm font-semibold flex justify-between w-full'>{title}</div>
            <input
              name={name}
              required
              placeholder={placeHolder}
              type={type}
              className='w-full h-12 text-xs px-2 xl:text-sm rounded border border-gray-300 focus-within:border-gray-300 focus:border-gray-300'
            />
          </label>
          )
        }
      </div>
      <div className="w-full">
        <CommonTextArea
          title="Mensaje"
          name="message"
          width="w-full"
          type="text"
          placeHolder="Escriba aquí su mensaje..."
          minlength="10"
          maxlength="500"
          required
        />
      </div>
      <CustomButton type='submit' title={pending ? 'Enviando...' : 'Enviar mensaje'} />
    </form>
  )
}

export default ContactForm