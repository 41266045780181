'use client'
import CustomButton from '@/components/common/CustomButton';
import React from 'react'

const AboutButton = () => {

  const handleScroll = (e) => {
    e.preventDefault();
    const element = document.querySelector('#contact');
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth', });
  };

  return (
    <CustomButton action={handleScroll} title='Contactanos'/>
  )
}

export default AboutButton