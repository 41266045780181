import React from 'react'

const CustomButton = ({ title, action = () => {}, disabled = false, type = 'button' }) => {

  return (
    <button type={type} onClick={action} disabled={disabled} className='flex h-11 items-center justify-center border px-4 text-sm md:text-base font-semibold select-none focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-1 focus-visible:outline-blue-800 rounded border-primary bg-primary text-white active:bg-primary'>
      {title}
    </button>
  )
}

export default CustomButton