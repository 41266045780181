//HOME 
export const attendanceCards = [
  {
    company: 'Sancor',
    companyAlt : 'Sancor',
    link : 'https://www.sancorseguros.com.ar/',
    companyImg: 'https://natalfwk.gruposancorseguros.com/nf-institucional/3.0.1/doc/imgs/logo.svg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Denuncia de Siniestros Automotor:',
        subtitle: '0800 777 4643'
      },
      {
        logo: '/icons/phone.png',
        title: 'Emergencias médicas (integro): ',
        subtitle: '0800 555 2850'
      },
      {
        logo: '/icons/phone.png',
        title: 'Asistencia al vehículo y personas:',
        subtitle: '0800 333 2766'
      },
    ],
  },
  // {
  //   company: 'Cio',
  //   companyImg: '/logos/allianz.png',
  //   data: [
  //     {
  //       logo: '/icons/phone.png',
  //       title: 'Consultas y reclamos ART: ',
  //       subtitle: '0-800-999-4652'
  //     },
  //     {
  //       logo: '/icons/phone.png',
  //       title: 'Asistencia automotor: ',
  //       subtitle: '0-800-999-0002'
  //     },
  //     {
  //       logo: '/brands/whatsapp.png',
  //       title: 'Asistencia Embarcaciones: ',
  //       subtitle: '+54 911-3251-222'
  //     }
  //   ],
  // },
  {
    company: 'La Holando',
    companyAlt : 'La Holando',
    link : 'https://www.laholando.com/',
    companyImg: '/logos/laHolando-con-fondo.jpg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Emergencias ART:',
        subtitle: '0-800-999-9719'
      },
      {
        logo: '/icons/phone.png',
        title: 'Asistencia automotor',
        subtitle: '0-800-999-0002'
      },
      {
        logo: '/icons/phone.png',
        title: 'Teléfono:',
        subtitle: '4321-7600'
      }
    ],
  },
  {
    company: 'Prevencion Art',
    link :  'https://www.prevencionart.com.ar/',
    companyAlt : 'Prevencion',
    companyImg: 'https://www.prevencionart.com.ar/resources/ART_color.svg',
    data: [
      {
        logo: '/icons/phone.png',
        title: 'Emergencias médicas:',
        subtitle: '0800 4444 278'
      },
      {
        logo: '/icons/phone.png',
        title: 'Consultas:',
        subtitle: '0800 5555 278'
      },
      {
        logo: '/brands/whatsapp.png',
        title: 'Consultas vía WhatsApp:',
        subtitle: '3493 66 1916'
      }
    ]
  }
]

export const skills = [
  {
    imgSrc: '/icons/insurance.png',
    title: 'Asegurados',
    description: 'Nuestros clientes cuentan con un servicio personalizado y especializado lo cual genera relaciones de largo plazo',
    alt: 'Chat image',
    id: 0
  },
  {
    imgSrc: '/icons/administrative.png',
    title: 'Administración de riesgos',
    description: 'Nos ocupamos de la cotización, comparación, contratación, actualización y renovación de tus pólizas',
    alt: 'Skills image',
    id: 1
  },
  {
    imgSrc: '/icons/innovation.png',
    title: 'Innovación',
    description: 'Contamos con un innovador sistema de gestión de pólizas con un rápido acceso en la misma página',
    alt: 'Graphic image',
    id: 2
  },
  {
    imgSrc: '/icons/academic.png',
    title: 'Capacitación y asesoramiento',
    description: 'Mediante unos simples pasos visualizarás todas tus pólizas y las podrás descargar y compartir ',
    alt: 'Tools image',
    id: 3
  }
]

export const contactInputs = [
  {
    name: 'user_name',
    title: 'Nombre completo',
    placeHolder: 'Escribe tu nombre',
    minlength: '1',
    maxlength: '30',
    required: true,
    width: 'w-full',
  },
  {
    name: 'user_email',
    title: 'Email',
    placeHolder: 'example@email.com...',
    type: 'email',
    minlength: '8',
    maxlength: '48',
    required: true,
    width: 'w-full',
  },
]
//GENERAL
export const navLinks = [
  {
    title: 'Útiles',
    scroll: '#attendance',
    offset: 150
  },
  {
    title: 'Contacto',
    scroll: '#contact',
    offset: 0
  },
  {
    title: 'Nosotros',
    scroll: '#about',
    offset: 100
  }
]
